.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.Appr {
  text-align: center;
}

.App-headerr {
  background: linear-gradient(to bottom right, #531f9c, #160b18);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.contactr {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contactr a {
  color: #ddd;
  text-decoration: none;
}

.contactr a:hover {
  color: #bbb;
}
.email-link {
  display: inline-block;
  background-color: #7d5fff;
  color: white;
  padding: 12px 24px;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;
  letter-spacing: 1px;
  font-weight: 600;
}

.email-link:hover {
  background-color: #6c55cc;
  transform: scale(1.05);
}

.email-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(125, 95, 255, 0.6);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
