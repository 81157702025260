* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Add this line */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.socialLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Add this line to allow wrapping */
  align-items: center;
  justify-content: center;
}

.socialIcon {
  margin-right: 15px;
  color: black;
  padding: 5px; /* Add this line to provide padding around the social icons */
}

.contactLink {
  color: black;
  font-weight: bold;
}

@media (max-width: 767px) {
  .socialLinks {
    flex-direction: row; /* Change this to row for mobile size */
  }

  .socialIcon {
    margin-right: 0;
    margin-bottom: 10px; /* Add margin-bottom to separate items */
  }

  .contactLink {
    margin-top: 10px;
    width: 100%; /* Add this line to make the contact link take the full width, so it will be on the next line */
    text-align: center; /* Add this line to center the text in mobile size */
  }
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #2b1245;
  /* border-radius: 6px; */
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #007bff;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 6px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
